import React, { useState } from 'react'
import { Menu, X } from 'lucide-react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import useWindowSize from '../../../farmapp/components/EnterpriseApp/UseWindowSize/UseWindowSize'

const Header = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const location = useLocation()
  const { width } = useWindowSize()

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible)
  }

  return (
    <div className='lg:py-[28px] px-[5%] py-3 w-full justify-between flex flex-row items-center'>
      <div className='flex flex-row gap-16 items-center z-[50]'>
        <div className='w-[200px]'>
          {location.pathname === '/products'
            ? (
              <img
                src={require('../../assets/blacklogo.png')}
                alt='Product Logo'
                className='h-[18px] lg:h-[25px]'
              />
              )
            : <img
                src={require('../../assets/logo-white.png')}
                alt='Product Logo'
                className='h-[18px] lg:h-[25px]'
              />}
        </div>
        <div className='hidden lg:flex items-center gap-5'>
          <div className='cursor-pointer'>
            <NavLink
              to='/'
              className={({ isActive }) =>
                isActive
                  ? 'text-[#ADEE68] font-[600] text-[16px] hover:text-[19px] underline'
                  : location.pathname === '/products'
                    ? 'font-[400] text-[18px] text-black'
                    : 'font-[400] text-[18px] text-[#DDDDDD]'}
            >
              <p>Home</p>
            </NavLink>
          </div>
          <div className='cursor-pointer'>
            <NavLink
              to='/about'
              className={({ isActive }) =>
                isActive
                  ? 'text-[#ADEE68] font-[600] text-[16px] hover:text-[19px] underline'
                  : location.pathname === '/products'
                    ? 'font-[400] text-[18px] text-black'
                    : 'font-[400] text-[18px] text-[#DDDDDD]'}
            >
              <p>About</p>
            </NavLink>
          </div>
          <div className='cursor-pointer'>
            <NavLink
              to='/products'
              className={({ isActive }) =>
                isActive && location.pathname.includes('/products')
                  ? 'text-darkGreen font-[600] text-[16px] hover:text-[19px] underline'
                  : location.pathname.includes('/products')
                    ? 'font-[400] text-[18px] text-darkGreen'
                    : 'font-[400] text-[18px] text-[#DDDDDD]'}
            >
              <p>Products</p>
            </NavLink>

          </div>
        </div>
      </div>
      <div className='hidden lg:flex flex-row gap-5 items-center z-[100]'>
        <Link to='/login'>
          <div className='border border-[#DDDDDD] py-2 px-6 rounded-[8px] cursor-pointer'>
            <p
              className={`font-[500] text-[16px] ${
            location.pathname === '/products' ? 'text-black' : 'text-white'
          }`}
            >
              Log in
            </p>
          </div>
        </Link>
        <Link to='/signup'>
          <div className='py-[10px] px-6 bg-primary rounded-md cursor-pointer'>
            <p className='font-[500] text-[#232221] text-[16px]'>
              Create an Account
            </p>
          </div>
        </Link>
      </div>

      {width < 1024 && (
        <div className='z-[100]' onClick={toggleDropdown}>
          <Menu
            className={
        location.pathname === '/products'
          ? 'text-black w-[24px] h-[24px]'
          : 'text-white w-[24px] h-[24px]'
      }
          />

          {/* Dropdown with animation */}
          <div
            className={`absolute inset-0 w-full h-screen bg-white px-[3%] py-[5%] transform transition-transform duration-300 ease-in ${
        dropdownVisible ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0'
      }`}
          >
            <div className='w-full flex justify-between items-center'>
              <img
                src={require('../../assets/blacklogo.png')}
                alt='Product Logo'
                className='h-[18px] lg:h-[25px]'
              />
              <X className='text-black w-[24px] h-[24px]' onClick={toggleDropdown} />
            </div>
            <div className='w-full flex flex-col justify-center items-center gap-6 pt-[25%] text-[15px] text-darkGreen'>
              {/* NavLink for Home */}
              <NavLink
                to='/'
                className={({ isActive }) =>
                  isActive ? 'text-darkGreen font-[600]' : 'text-[#444444]'}
              >
                <h1>Home</h1>
              </NavLink>

              {/* NavLink for About */}
              <NavLink
                to='/about'
                className={({ isActive }) =>
                  isActive ? 'text-darkGreen font-[600]' : 'text-[#444444]'}
              >
                <h1>About</h1>
              </NavLink>

              {/* NavLink for Products */}
              <NavLink
                to='/products'
                className={({ isActive }) =>
                  isActive ? 'text-darkGreen font-[600]' : 'text-[#444444]'}
              >
                <h1>Products</h1>
              </NavLink>

              <NavLink to='/login'>
                <h1>Login</h1>
              </NavLink>

              <NavLink to='/signup' className='w-full'>
                <h1 className='w-full bg-[#ADEE68] text-darkGreen text-[14px] rounded-lg h-[48px] center font-[500]'>
                  Create Account
                </h1>
              </NavLink>
            </div>
          </div>
        </div>
      )}

    </div>
  )
}

export default Header
