import React from 'react'
import DashBoardHeader from '../DashBoard/DashBoardHeader/DashBoardHeader'
import { Plus } from 'lucide-react'
import CalendarDatatable from './OperationalCalendarDatatable/OperationalCalendarDatatable'
import { calendarData } from '../../../data/static'

const OperationalCalendar = () => {
  return (
    <div className='farmwrapper'>
      <DashBoardHeader
        title='Calendar'
        btntext='Create Operational Calendar'
        link='/operational-calendar/create-operational-calendar'
        icon={<Plus size={20} />}
      />
      <h1 className='w-full ts'>All Calendar</h1>
      <CalendarDatatable data={calendarData} />
    </div>
  )
}

export default OperationalCalendar
