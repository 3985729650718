import React, { useState } from 'react'
import BackButton from '../../../SharedComponents/BackButton/BackButton'
import { Controller, useForm } from 'react-hook-form'
import SelectInput from '../../../SharedComponents/SelectInput/SelectInput'
import TextInput from '../../../SharedComponents/TextInput/TextInput'
import { CalendarDays } from 'lucide-react'
import CalendarSelector from '../OperationalCalendarSelector/OperationalCalendarSelector'
import Modal from '../../../SharedComponents/Modal/Modal'
import useBackendService from '../../../../../config/services/backend-service'
import { format, startOfToday } from 'date-fns'
const OperationalCalendarCreate = () => {
  const [modal, setModal] = useState({})
  const today = startOfToday()
  const [selectedDay, setSelectedDay] = useState('')
  const formattedSelectedDay = selectedDay ? format(new Date(selectedDay), 'dd-MM-yyyy') : ''
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isDirty, isValid }
  } = useForm({ mode: 'onChange' })
  const { mutate: createCalendar } = useBackendService('', 'post', {
    onSuccess: () => {
      setModal({
        type: 'success',
        title: 'Calendar submitted',
        desc: `Well done. You have successfully submitted a calendar. Please look out for your response on ${formattedSelectedDay}`,
        link: '/operationalcalendar'
      })
      document.getElementById('my_modal_5').showModal()
    },
    onError: () => {
      setModal({
        type: 'error',
        title: 'Unable to submit calendar',
        desc: 'You are unable to submit a calendar at the moment.'

      })
      document.getElementById('my_modal_5').showModal()
    }
  })
  const onSubmit = async data => {
    createCalendar(data)
    console.log(data)
  }
  // farm data
  const farms = [

  ]
  // products data
  const products = [

  ]
  const handleSave = () => {
    if (selectedDay) {
      setValue('date', formattedSelectedDay)
      console.log('Selected Date:', selectedDay)
    }
  }
  return (
    <div className='farmwrapper'>
      <BackButton />
      <div className='space-y-0 lg:space-y-1'>
        <h1 className='lg:text-3xl text-xl text-black'>Create calendar</h1>
        <p className='text-[#666666] text-sm lg:text-sm'>
          Fill in the details of your calendar
        </p>
      </div>

      <form className='form-wrapper' onSubmit={handleSubmit(onSubmit)}>
        <div className='flex flex-col lg:flex-row gap-5 lg:gap-10 items-center'>
          <div className='w-full lg:w-[418px]'>
            <SelectInput
              control={control}
              Controller={Controller}
              data={farms}
              title='Farm'
              name='farm'
              placeholder='Select farm'
            />
          </div>
          <div className='w-full lg:w-[418px]'>
            <SelectInput
              control={control}
              Controller={Controller}
              data={products}
              title='Product'
              name='product'
              placeholder='Select product'
            />
          </div>
        </div>
        <div className='flex flex-col lg:flex-row gap-5 lg:gap-10 items-center'>
          <div className='w-full lg:w-[418px]'>
            <TextInput
              name='date'
              register={register}
              errors={errors}
              text='Date'
              dvalue={formattedSelectedDay}
              placeholder='Input here'
              calendaricon={<CalendarDays size={20} onClick={() => document.getElementById('calendar').showModal()} />}
            />
          </div>
          <div className='w-full lg:w-[418px]'>
            <div className='w-full lg:w-[418px]'>
              <TextInput
                name='landsize'
                register={register}
                errors={errors}
                text='Land size'
                placeholder='Input here'
              />
            </div>
          </div>
        </div>
        <div className='flex flex-col lg:flex-row gap-5 lg:gap-10 items-center'>
          <div className='w-full lg:w-[418px]'>
            <TextInput
              name='location'
              register={register}
              errors={errors}
              text='Location'
              placeholder='Input here'
            />
          </div>
        </div>

        <div className='w-full lg:w-[418px]'>
          <button
            type='submit'
            className={`w-full h-[50px] ts ${
            isDirty && isValid
              ? 'proceed-btn bg-darkGreen'
              : 'rounded-[8px] bg-darkash text-black'
          }`}
            disabled={!isDirty || !isValid}
          >
            Create Calendar
          </button>
        </div>
      </form>
      <CalendarSelector
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
        today={today}
        onSave={handleSave}
      />
      {/* Conditionally render the modal */}
      {modal.type && (
        <Modal
          type={modal.type}
          title={modal.title}
          desc={modal.desc}
          link={modal.link}
        />
      )}
    </div>
  )
}

export default OperationalCalendarCreate
