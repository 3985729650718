import React, { useState } from 'react'
import { BasicPlanPricingContent, EnterprisePricingContent, ProPlanPricingContent } from '../../../static'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../config/contexts/authProvider'

const PricingPlanSection = () => {
  const [currentCategory, setCurrentCategory] = useState('Monthly')
  const { setSignupField } = useAuth()
  const handleClick = (planType, e) => {
    // e.preventDefault()
    setSignupField('plan', planType)
  }

  return (
    <section className='flex flex-col gap-5 lg:gap-10 items-center px-3  lg:py-[64px]  '>
      <div className='flex flex-col gap-1 lg:gap-[10px] '>
        <p className=' font-[800] text-[24px] lg:text-[30px] text-primary text-center '>
          Pricing plan
        </p>
        <p className='font-[400] text-[16px] text-grey '>No surprise fees.</p>
      </div>
      <div>
        <Category
          category={currentCategory}
          onCategoryChange={setCurrentCategory}
        />
      </div>
      <div className=' flex flex-col lg:flex-row gap-5  lg:gap-10 items-center lg:items-end  w-full justify-center '>
        <div className='p-[20px] lg:p-[45px] lg:w-[400px] w-full  '>
          <div className='flex flex-col gap-3 lg:gap-4 text-black w-full'>
            <p className='  text-[20px] lg:text-[36px] font-[600] text-primary '>
              Free
            </p>
            <div className=' flex flex-col gap-[2px] '>
              <p className=' text-[16px] lg:text-[28px] font-[600] text-black '>
                Basic Plan
              </p>
              <p>Ideal for small scale farms</p>
            </div>
            <div className=' flex flex-col gap-2 lg:gap-2 '>
              {BasicPlanPricingContent.map(item => (
                <div className='flex flex-row gap-2 lg:gap-1 ' key={item.id}>
                  <img
                    src={require('../../../assets/tick-circle_2.png')}
                    alt=''
                    className='lg:w-5 lg:h-5 w-5 h-5 '
                  />
                  <p className=' text-[13px] lg:text-[16px] '>
                    {item.content}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <Link to='/signup/basicplan' onClick={e => handleClick('Basic', e)}>
            <div className=' w-[336px] bg-[#144618] cursor-pointer items-center justify-center py-3 rounded-lg mt-10 lg:mt-20  '>
              <p className='text-white text-center text-[13px] lg:text-[15px] font-[600] '>
                Choose plan
              </p>
            </div>
          </Link>
        </div>
        <div
          className='flex flex-col bg-[#144618] rounded-lg p-[20px] lg:p-[30px] gap-3 lg:w-[400px] bg-planbg bg-right bg-no-repeat w-full'
          style={{ backgroundSize: '20%' }}
        >

          <div className=' justify-end flex '>
            <div className='w-[121px] bg-white py-2 px-2 rounded-lg '>
              <p className='text-primary text-center text-[13px] font-[600] '>
                MOST POPULAR
              </p>
            </div>
          </div>
          <p className='font-[700]  text-[20px] lg:text-[36px] text-white '>
            {currentCategory === 'Monthly' ? '₦5,000' : '₦60,000'}
            <span className=' text-[12px] lg:text-[17px]'>
              {currentCategory === 'Monthly' ? '/month' : 'yearly'}
            </span>
          </p>
          <div className='space-y-1'>
            <p className='font-[700] text-[16px] lg:text-[28px] text-white '>
              Pro Plan
            </p>
            <p className='font-[400] text-[13px] lg:text-[16px] text-white '>
              Perfect for Growing Farms
            </p>
          </div>
          <div className=' flex flex-col gap-2 lg:gap-1 '>
            {ProPlanPricingContent.map(item => (
              <div className='flex flex-row gap-2 w-[95%] ' key={item.id}>
                <img
                  src={require('../../../assets/check-circle-1.png')}
                  alt=''
                  className='lg:w-5 lg:h-5 w-5 h-5 '
                />
                <p className=' text-white text-[13px] lg:text-[16px] '>
                  {item.content}
                </p>
              </div>
            ))}
          </div>
          <div className=' flex flex-row w-full lg:justify-center justify-start items-center lg:items-center '>
            <Link to='/signup/basicplan' onClick={e => handleClick('Pro', e)}>
              <div className=' w-[336px] self-center cursor-pointer bg-white mt-10 lg:mt-20 items-center justify-center py-3 rounded-lg '>
                <p className='text-primary text-center text-[13px] lg:text-[15px] font-[600] '>
                  Choose plan
                </p>
              </div>
            </Link>
          </div>
        </div>
        <div className=' p-[20px]  lg:p-[30px] lg:w-[400px] w-full '>
          <div className='flex flex-col gap-3 lg:gap-4 text-black'>
            <p className='font-[700]  text-[20px] lg:text-[36px] text-darkGreen'>
              {currentCategory === 'Monthly' ? '₦100,000' : '₦1,200,000'}
              <span className=' text-[12px] lg:text-[17px]'>
                {currentCategory === 'Monthly' ? '/month' : 'yearly'}
              </span>
            </p>
            <div className='space-y-1'>
              <p className='  text-[16px] lg:text-[28px] font-[600] '>
                Enterprise
              </p>
              <p className='font-[400] text-[13px] lg:text-[16px]  '>
                Plan for Large-Scale Operations
              </p>
            </div>
            <div className=' flex flex-col gap-2 lg:gap-2 '>
              {EnterprisePricingContent.map(item => (
                <div
                  className='flex flex-row gap-1 items-start '
                  key={item.id}
                >
                  <img
                    src={require('../../../assets/tick-circle_2.png')}
                    alt=''
                    className='lg:w-5 lg:h-5 w-5 h-5 '
                  />
                  <p className=' text-[13px] lg:text-[16px] '>
                    {item.content}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <Link
            to='/signup/enterpriseplan'
            onClick={e => handleClick('Enterprise', e)}
          >
            <div className=' w-[336px] bg-[#144618] cursor-pointer items-center justify-center py-3 rounded-lg mt-10 lg:mt-20  '>
              <p className='text-white text-center text-[13px] lg:text-[15px] font-[600] '>
                Choose plan
              </p>
            </div>
          </Link>
        </div>
      </div>
    </section>
  )
}
const Category = ({ category, onCategoryChange }) => {
  const [selectedCategory, setSelectedCategory] = useState(category)

  const handleCategoryChange = item => {
    setSelectedCategory(item)
    onCategoryChange(item)
  }

  return (
    <div className=' flex flex-row items-center bg-[#EEEEEE] rounded-lg p-1 lg:p-2 '>
      {['Monthly', 'Yearly'].map((item, index) => (
        <div
          key={index}
          className={
              selectedCategory === item
                ? 'bg-[#144618] rounded-lg cursor-pointer '
                : 'cursor-pointer'
            }
          onClick={() => handleCategoryChange(item)}
        >
          <div className=' px-6 lg:px-8 lg:py-4 py-4 '>
            <p
              className={
                  selectedCategory === item
                    ? ' font-[800] text-white text-[12px] lg:text-[16px] '
                    : ' text-[#848199] text-[13px] lg:text-[16px] font-[800] '
                }
            >
              {item}
            </p>
          </div>
        </div>
      ))}
    </div>
  )
}
export default PricingPlanSection
