import React from 'react'
import { useLocation } from 'react-router-dom'
import './Sidebar.css'

const SideBar = ({
  name,
  icon,
  orange,
  link,
  isSubMenu,
  isManagementOpen,
  isMarketOpen
}) => {
  const location = useLocation()
  const path = location.pathname
  const isExpanded =
    (name === 'Management' && isManagementOpen) ||
    (name === 'Market' && isMarketOpen)

  const isActive =
    (path === '/' && link === '/dashboard') || path.startsWith(link)

  const renderIcon = () => {
    const isBackgroundWhite =
      (!link && isExpanded) || (!link && isSubMenu) || (isActive && !isSubMenu)
    if (icon || orange) {
      return (
        <img
          src={isBackgroundWhite ? orange : icon}
          alt={`${name} icon`}
          className='w-[20px] h-[20px]'
        />
      )
    } else {
      return null
    }
  }

  const renderDropdownArrow = () => {
    if (!link && (name === 'Management' || name === 'Market')) {
      return isExpanded ? (
        <svg
          width='12'
          height='8'
          viewBox='0 0 12 8'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          aria-label='expanded'
        >
          <path
            d='M11 6.5L6 1.5L1 6.5'
            stroke='#144618'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      ) : (
        <svg
          width='12'
          height='8'
          viewBox='0 0 12 8'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          aria-label='collapsed'
        >
          <path
            d='M1 1.5L6 6.5L11 1.5'
            stroke='white'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      )
    } else {
      return null
    }
  }

  return (
    <div className='enterprise_link-wrapper'>
      <a
        href={link}
        className={`w-full nav-link ${
          (!link && isExpanded) || (!link && isSubMenu)
            ? 'rounded-lg bg-white'
            : isActive
            ? isSubMenu
              ? ''
              : 'rounded-lg bg-white'
            : ''
        }`}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end'
          }}
        >
          <div className='enterprise-link-container'>
            {renderIcon()}

            <h1
              className={`font-medium ${
                (!link && isExpanded) ||
                (!link && isSubMenu) ||
                (isActive && !isSubMenu)
                  ? 'text-darkgreen font-semibold'
                  : isActive
                  ? isSubMenu
                    ? 'text-[#ADEE68] font-semibold'
                    : 'text-darkgreen font-semibold'
                  : isSubMenu
                  ? 'text-[#AAAAAA] font-semibold'
                  : 'text-white'
              }`}
            >
              {name}
            </h1>
          </div>
          <div style={{ marginRight: '35px' }} className='m-auto'>
            {renderDropdownArrow()}
          </div>
        </div>
      </a>
    </div>
  )
}

export default SideBar