import React, { useEffect, useState } from 'react'
import useBackendService from '../../../../config/services/backend-service'

function AdminHome () {
  const [userCounts, setUserCounts] = useState({
    totalUsers: 0,
    enterpriseUsers: 0,
    proUsers: 0,
    basicUsers: 0
  })
  const { mutate: dashCount } = useBackendService(
    '/admin/dashboard_count',
    'get',
    {
      onSuccess: response => {
        setUserCounts(response.data)
      },
      onError: error => {
        console.error('Failed to fetch products:', error)
      }
    }
  )

  useEffect(() => {
    dashCount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='w-full h-full flex justify-center items-center'>
      <div className='w-full h-38 p-5 border-2 border-gray-300 rounded-lg relative'>
        <div className='absolute top-2 bottom-2 left-1/4 transform -translate-x-1/2 border-l border-gray-300' />
        <div className='absolute top-2 bottom-2 left-2/4 transform -translate-x-1/2 border-l border-gray-300' />
        <div className='absolute top-2 bottom-2 left-3/4 transform -translate-x-1/2 border-l border-gray-300' />

        <div className='w-full h-full grid grid-cols-4 gap-4 p-4'>
          <div className='flex items-center justify-center'>
            <div className='p-5'>
              <svg
                width='46'
                height='46'
                viewBox='0 0 46 46'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <circle
                  cx='23'
                  cy='23'
                  r='22.5'
                  stroke='#3961E4'
                  stroke-opacity='0.2'
                />
                <path
                  d='M26.3333 30.5V28.8333C26.3333 27.9493 25.9821 27.1014 25.357 26.4763C24.7319 25.8512 23.884 25.5 23 25.5H18C17.1159 25.5 16.2681 25.8512 15.6429 26.4763C15.0178 27.1014 14.6666 27.9493 14.6666 28.8333V30.5'
                  stroke='#3961E4'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M20.5 22.1667C22.3409 22.1667 23.8333 20.6743 23.8333 18.8333C23.8333 16.9924 22.3409 15.5 20.5 15.5C18.659 15.5 17.1666 16.9924 17.1666 18.8333C17.1666 20.6743 18.659 22.1667 20.5 22.1667Z'
                  stroke='#3961E4'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M31.3334 30.5001V28.8334C31.3328 28.0948 31.087 27.3774 30.6345 26.7937C30.182 26.2099 29.5485 25.793 28.8334 25.6084'
                  stroke='#3961E4'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M26.3334 15.6084C27.0504 15.792 27.6859 16.209 28.1397 16.7937C28.5936 17.3783 28.8399 18.0974 28.8399 18.8376C28.8399 19.5777 28.5936 20.2968 28.1397 20.8815C27.6859 21.4661 27.0504 21.8831 26.3334 22.0667'
                  stroke='#3961E4'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            </div>
            <div className='flex flex-col items-center justify-center'>
              <p className='mt-2 text-gray-600 text-lg font-bold'>
                Total Users
              </p>
              <span className='text-xl font-bold'>{userCounts.totalUsers}</span>
            </div>
          </div>

          <div className='flex items-center justify-center'>
            <div className='p-5'>
              <svg
                width='46'
                height='46'
                viewBox='0 0 46 46'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <circle
                  cx='23'
                  cy='23'
                  r='22.5'
                  stroke='#5CB85C'
                  stroke-opacity='0.2'
                />
                <g clip-path='url(#clip0_86_18)'>
                  <path
                    d='M23 31.3334C27.6023 31.3334 31.3333 27.6025 31.3333 23.0001C31.3333 18.3977 27.6023 14.6667 23 14.6667C18.3976 14.6667 14.6666 18.3977 14.6666 23.0001C14.6666 27.6025 18.3976 31.3334 23 31.3334Z'
                    stroke='#43936C'
                    stroke-width='1.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path
                    d='M26.3333 19.6667H21.3333C20.8913 19.6667 20.4673 19.8423 20.1548 20.1549C19.8422 20.4675 19.6666 20.8914 19.6666 21.3334C19.6666 21.7754 19.8422 22.1994 20.1548 22.5119C20.4673 22.8245 20.8913 23.0001 21.3333 23.0001H24.6666C25.1087 23.0001 25.5326 23.1757 25.8451 23.4882C26.1577 23.8008 26.3333 24.2247 26.3333 24.6667C26.3333 25.1088 26.1577 25.5327 25.8451 25.8453C25.5326 26.1578 25.1087 26.3334 24.6666 26.3334H19.6666'
                    stroke='#43936C'
                    stroke-width='1.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path
                    d='M23 28V18'
                    stroke='#43936C'
                    stroke-width='1.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_86_18'>
                    <rect
                      width='20'
                      height='20'
                      fill='white'
                      transform='translate(13 13)'
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>

            <div className='flex flex-col items-center justify-center'>
              <p className='mt-2 text-gray-600 text-lg font-bold'>
                Enterprise Users
              </p>
              <span className='text-xl font-bold'>
                {userCounts.enterpriseUsers}
              </span>
            </div>
          </div>
          <div className='flex items-center justify-center'>
            <div className='p-5'>
              <svg
                width='46'
                height='46'
                viewBox='0 0 46 46'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <circle
                  cx='23'
                  cy='23'
                  r='22.5'
                  stroke='#733620'
                  stroke-opacity='0.2'
                />
                <g clip-path='url(#clip0_86_29)'>
                  <path
                    d='M22.1667 25.5001H23.8333C24.2754 25.5001 24.6993 25.3245 25.0118 25.0119C25.3244 24.6994 25.5 24.2754 25.5 23.8334C25.5 23.3914 25.3244 22.9675 25.0118 22.6549C24.6993 22.3423 24.2754 22.1667 23.8333 22.1667H21.3333C20.8333 22.1667 20.4167 22.3334 20.1667 22.6667L15.5 27.1667'
                    stroke='#733620'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path
                    d='M18.8333 30.5L20.1666 29.3333C20.4166 29 20.8333 28.8333 21.3333 28.8333H24.6666C25.5833 28.8333 26.4166 28.5 27 27.8333L30.8333 24.1666C31.1549 23.8628 31.3426 23.4436 31.3551 23.0013C31.3676 22.559 31.2039 22.1299 30.9 21.8083C30.5961 21.4867 30.1769 21.2991 29.7346 21.2866C29.2923 21.2741 28.8632 21.4378 28.5416 21.7416L25.0416 24.9916'
                    stroke='#733620'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path
                    d='M14.6667 26.3333L19.6667 31.3333'
                    stroke='#733620'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path
                    d='M26.3334 22.9166C27.668 22.9166 28.75 21.8346 28.75 20.4999C28.75 19.1652 27.668 18.0833 26.3334 18.0833C24.9987 18.0833 23.9167 19.1652 23.9167 20.4999C23.9167 21.8346 24.9987 22.9166 26.3334 22.9166Z'
                    stroke='#733620'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path
                    d='M18 19.6667C19.3807 19.6667 20.5 18.5475 20.5 17.1667C20.5 15.786 19.3807 14.6667 18 14.6667C16.6193 14.6667 15.5 15.786 15.5 17.1667C15.5 18.5475 16.6193 19.6667 18 19.6667Z'
                    stroke='#733620'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_86_29'>
                    <rect
                      width='20'
                      height='20'
                      fill='white'
                      transform='translate(13 13)'
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>

            <div className='flex flex-col items-center justify-center'>
              <p className='mt-2 text-gray-600 text-lg font-bold'>Pro Users</p>
              <span className='text-xl font-bold'> {userCounts.proUsers}</span>
            </div>
          </div>
          <div className='flex items-center justify-center'>
            <div className='p-5'>
              <svg
                width='46'
                height='46'
                viewBox='0 0 46 46'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <circle
                  cx='23'
                  cy='23'
                  r='22.5'
                  stroke='#F28F16'
                  stroke-opacity='0.2'
                />
                <path
                  d='M26.3333 30.5V28.8333C26.3333 27.9493 25.9821 27.1014 25.357 26.4763C24.7319 25.8512 23.884 25.5 23 25.5H18C17.1159 25.5 16.2681 25.8512 15.643 26.4763C15.0178 27.1014 14.6667 27.9493 14.6667 28.8333V30.5'
                  stroke='#F28F16'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M20.5 22.1667C22.3409 22.1667 23.8333 20.6743 23.8333 18.8333C23.8333 16.9924 22.3409 15.5 20.5 15.5C18.659 15.5 17.1667 16.9924 17.1667 18.8333C17.1667 20.6743 18.659 22.1667 20.5 22.1667Z'
                  stroke='#F28F16'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M26.3333 22.1667L28 23.8333L31.3333 20.5'
                  stroke='#F28F16'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            </div>

            <div className='flex flex-col items-center justify-center'>
              <p className='mt-2 text-gray-600 text-lg font-bold'>
                Basic Users
              </p>
              <span className='text-xl font-bold'>{userCounts.basicUsers}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminHome
