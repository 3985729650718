import {
  ChevronDown,
  ChevronRight,
  ChevronUp,
  ListFilter,
  X
} from 'lucide-react'
import React, { useState } from 'react'

const Filter = ({ location, products }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)
  const [selectedItems, setSelectedItems] = useState([])
  const handleSelect = item => {
    setSelectedItems(prevItems => [...prevItems, item.id])
  }
  const handleRemoveItem = item => {
    setSelectedItems(prevItems => prevItems.filter(id => id !== item))
  }
  return (
    <div className='lg:w-[65%]  hidden lg:flex justify-end items-center'>
      <div className='filter-btn selected-items list-none '>
        {selectedItems.map(item => (
          <div key={item} className='flex items-center text-sm gap-2'>
            <h1>{item}</h1>
            <button onClick={() => handleRemoveItem(item)}>
              <X size={20} />
            </button>
          </div>
        ))}
      </div>
      <details
        className='dropdown  text-black font-semibold  '
        onClick={() => setIsOpen(!isOpen)}
      >
        <summary className='btn lg:m-1 center shadow-none fill '>
          <ListFilter size={20} />
          Filter
          {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </summary>
        <main className='menu drop-item w-44 '>
          <div className=' w-full h-[40px] rounded-t-lg p-3'>
            <h1>General Overview</h1>
          </div>

          <details className='dropdown  h-[45px]'>
            <summary
              className={`drop-content  ${
                selectedOption === 'By farms' ? 'bg-[#E9E9E9]' : ''
              } `}
              onClick={() => setSelectedOption('By farms')}
            >
              Location <ChevronRight size={20} />
            </summary>

            <div className=' aside '>
              {location.map(item => (
                <div key={item} className='p-3 capitalize '>
                  <h1 onClick={() => handleSelect(item)}>{item?.label}</h1>
                </div>
              ))}
            </div>
          </details>

          <details className='dropdown  h-[45px]'>
            <summary
              className={`drop-content ${
                selectedOption === 'By products' ? 'bg-[#E9E9E9]' : ''
              } `}
              onClick={() => setSelectedOption('By products')}
            >
              Products <ChevronRight size={20} />
            </summary>
            <div className='menu dropdown-content aside'>
              {products.map(item => (
                <div className='p-3 capitalize' key={item.id}>
                  <h1 onClick={() => handleSelect(item)}>{item?.label}</h1>
                </div>
              ))}
            </div>
          </details>
        </main>
      </details>
    </div>
  )
}

export default Filter
