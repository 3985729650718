import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import TextInput from '../../SharedComponents/TextInput/TextInput'
import { ChevronDown } from 'lucide-react'
import EnterpriseBusinessTypeSelector from './EnterpriseBusinessTypeSelector'
import EnterpriseSignUpConfirmation from './EnterpriseSignUpConfirmation'
import useBackendService from '../../../../config/services/backend-service'
import { useAuth } from '../../../../config/contexts/authProvider'

const EnterpriseCeoRegistration = ({ sucess, setSucess }) => {
  const { farm } = useAuth()
  const [selectedValue, setSelectedValue] = useState('')

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      businesstype: []
    }
  })
  const handleSave = () => {
    if (selectedValue) {
      const selectedSubtypes = Object.keys(selectedValue)
      setValue('businesstype', selectedSubtypes, { shouldValidate: true })
    }
  }

  const { mutate } = useBackendService('/auth/farm', 'post', {
    onSuccess: data => {
      setSucess(true)
    },
    onError: error => {
      console.error('failed:', error)
    }
  })

  const onSubmit = async data => {
    const payload = {
      email: farm.email,
      phoneNumber: farm.phoneNumber,
      isParentFarm: farm.isParentFarm,
      name: farm.farmname,
      state: farm.location,
      additionalData: {
        staffs: farm.staffs,
        branches: farm.branches,
        type: data.businesstype,
        ceo_name: data.ceoname,
        ceo_email: data.ceoemail,
        cac_number: data.cacnumber
      }
    }
    mutate(payload)
  }

  return (
    <div className='content-cover'>
      {/* Conditionally render success content */}
      {sucess ? (
        <EnterpriseSignUpConfirmation />
      ) : (
        // Show form if success is false
        <>
          <div className='w-full space-y-1'>
            <h1 className='header-text'>Business Details</h1>
            <p className='text-black'>
              Kindly fill in your details to set up your account
            </p>
          </div>

          <form className='form-wrapper' onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              name='ceoname'
              register={register}
              errors={errors}
              text='CEO name'
              placeholder='CEO name'
            />

            <TextInput
              name='ceoemail'
              register={register}
              errors={errors}
              text='CEO email'
              placeholder='CEO email'
              type='email'
            />

            <TextInput
              name='cacnumber'
              register={register}
              errors={errors}
              text='CAC number'
              placeholder='Input CAC number'
            />

            <TextInput
              name='businesstype'
              register={register}
              errors={errors}
              text='Type of Business'
              dvalue={
                Array.isArray(selectedValue)
                  ? selectedValue.join(', ')
                  : selectedValue
              }
              placeholder='Select business type'
              calendaricon={
                <ChevronDown
                  size={20}
                  onClick={() =>
                    document.getElementById('business').showModal()}
                />
              }
            />

            <button type='submit' className='activebutton'>
              Submit
            </button>

            <EnterpriseBusinessTypeSelector
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              save={handleSave}
            />
          </form>

          <p className='login-text'>
            Have an account?{' '}
            <a href='/login' className='login-link'>
              Log in
            </a>
          </p>
        </>
      )}
    </div>
  )
}

export default EnterpriseCeoRegistration
