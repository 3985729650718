import React from 'react'
import BackButton from '../../../SharedComponents/BackButton/BackButton'
import Button from '../../../SharedComponents/Button/Button'
import DescriptionCalendar from './OperationalCalendarDescription/OperationalDescriptionCalendar'
import { adminCalendarDescription } from '../../../../data/static'
import { format, parseISO } from 'date-fns'

const OperationalCalendarDescription = () => {
  return (
    <div className='w-full h-full p-[2%]'>
      <div className='w-full flex justify-between items-center'>
        <BackButton />
        <div className='flex justify-end items-center gap-3 w-[55%] lg:w-[30%]'>
          <Button
            title='Contact Us for More'
            classname='bg-[#ADEE68] text-darkGreen w-[200px]'
          />
          <Button
            title='Print'
            classname='text-white bg-darkGreen w-[100px]'
          />
        </div>
      </div>
      <h1 className='font-semibold w-full'>Calendar</h1>
      <div className='w-full flex justify-between items-start mt-3 gap-2 lg:flex-row flex-col'>
        <div className='lg:w-[50%] h-[38vh] bg-darkGreen text-white capitalize p-8 ts rounded-md w-full center flex-col gap-5'>
          <div className='w-full flex justify-between items-center'>
            <h1>Parent farm:</h1>
            <h1>{adminCalendarDescription.farmName}</h1>
          </div>
          <div className='w-full flex justify-between items-center'>
            <h1>Sub-farm:</h1>
            <h1>{adminCalendarDescription.subFarm}</h1>
          </div>
          <div className='w-full flex justify-between items-center'>
            <h1>Product:</h1>
            <h1>{adminCalendarDescription.product}</h1>
          </div>
          <div className='w-full flex justify-between items-center'>
            <h1>Date requested:</h1>
            <h1>{adminCalendarDescription.date}</h1>
          </div>
          <div className='w-full flex justify-between items-center'>
            <h1>Land size:</h1>
            <h1>{adminCalendarDescription.landSize}</h1>
          </div>
          <div className='w-full flex justify-between items-center'>
            <h1>Location:</h1>
            <h1>{adminCalendarDescription.location}</h1>
          </div>
        </div>
        <div className='w-full lg:w-[50%] pb-[5%]'>
          <DescriptionCalendar adminCalendarDescription={adminCalendarDescription} />
        </div>
      </div>

      {/* bottom section  */}
      <div className='w-full flex lg:flex-row flex-col justify-between items-start gap-3 pt-4'>
        {/* left content */}
        <div className='w-full lg:w-1/2 flex flex-col center'>
          {/* Return investment */}
          <div className='w-full'>
            <h1 className='font-semibold'>Return on Investment</h1>
            {adminCalendarDescription.ReturnInvestment.map((item, index) => (
              <p className='ts pt-3' key={index}>
                {item}
              </p>
            ))}
          </div>
          <div className='w-full pt-4'>
            <h1 className='font-semibold'>Business Cases</h1>
            {adminCalendarDescription.BusinessCases.map((item, index) => (
              <p className='ts pt-3' key={index}>
                {item}
              </p>
            ))}
          </div>
          <div className='w-full pt-4'>
            <h1 className='font-semibold'>Challenges</h1>
            {adminCalendarDescription.BusinessCases.map((item, index) => (
              <p className='ts pt-3' key={index}>
                {item}
              </p>
            ))}
          </div>
          <div className='w-full pt-4'>
            <h1 className='font-semibold'>Challenge Mitigators</h1>
            {adminCalendarDescription.BusinessCases.map((item, index) => (
              <p className='ts pt-3' key={index}>
                {item}
              </p>
            ))}
          </div>
          <div className='w-full pt-4'>
            <h1 className='font-semibold'>Value Chain Analysis</h1>
            {adminCalendarDescription.BusinessCases.map((item, index) => (
              <p className='ts pt-3' key={index}>
                {item}
              </p>
            ))}
          </div>
          <div className='w-full pt-4'>
            <h1 className='font-semibold'>Preservation Methods</h1>
            {adminCalendarDescription.BusinessCases.map((item, index) => (
              <p className='ts pt-3' key={index}>
                {item}
              </p>
            ))}
          </div>
        </div>
        {/* right content */}
        <div className='w-full lg:w-1/2 flex flex-col justify-center items-start'>
          <div className='w-full bg-darkGreen rounded-md p-4 text-white'>
            <h1 className='font-semibold'>Calendar Information</h1>
            {adminCalendarDescription.Event.map((item, index) => (
              <div className='w-full' key={index}>
                <h1 className='ts font-medium pt-4'>
                  {format(parseISO(item.date), 'MMMM d, yyyy')}
                </h1>
                <p className='ts pt-2'>{item.eventdescription}</p>
              </div>
            ))}
          </div>
          <div className='w-full pt-4'>
            <h1 className='font-semibold'>Planting Methods</h1>
            {adminCalendarDescription.BusinessCases.map((item, index) => (
              <p className='ts pt-3' key={index}>
                {item}
              </p>
            ))}
          </div>
          <div className='w-full pt-4'>
            <h1 className='font-semibold'>Harvesting Methods</h1>
            {adminCalendarDescription.BusinessCases.map((item, index) => (
              <p className='ts pt-3' key={index}>
                {item}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OperationalCalendarDescription
