import React, { useRef, useState, useEffect } from 'react'
import { Clock } from 'lucide-react'
import BackButton from '../../../SharedComponents/BackButton/BackButton'
import NoteComponent from '../../../SharedComponents/NoteComponent/NoteComponent'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import TextInput from '../../../SharedComponents/TextInput/TextInput'
import TextArea from '../../../SharedComponents/TextAreaInput/TextAreaInput'
import ImageInput from '../../../SharedComponents/ImageInput/ImageInput'
import { useAuth } from '../../../../../config/contexts/authProvider'
import useBackendService from '../../../../../config/services/backend-service'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Modal from '../../../SharedComponents/Modal/Modal'

const NewsArticleDetails = () => {
  const { user } = useAuth()
  const { id } = useParams()
  const [modalData, setModalData] = useState({
    type: '',
    title: '',
    desc: ''
  })
  const [preview, setPreview] = useState(null)
  const [imageUploaded, setImageUploaded] = useState(false)
  const [data, setData] = useState({})
  const imageInput = useRef(null)

  const { mutate: fetchArticle } = useBackendService(`/articles/${id}`, 'get', {
    onSuccess: response => {
      setData(response.data)
      setFormValues(response.data)
    },
    onError: error => {
      console.error('Failed to fetch article data:', error)
      toast.error('Failed to fetch article data')
    }
  })

  const { mutate: updateArticle } = useBackendService(
    `/articles/${id}`,
    'put',
    {
      onSuccess: response => {
        setModalData({
          title: 'Article updated',
          desc: 'Well done , you have successfully updated an article',
          type: 'success',
          link: '/admin-news'
        })
        document.getElementById('my_modal_5').showModal()
      },
      onError: error => {
        setModalData({
          type: 'error',
          title: 'Submission failed',
          desc: 'You are unable to update the article'
        })
        document.getElementById('my_modal_5').showModal()
      }
    }
  )

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isValid }
  } = useForm({ mode: 'onChange' })

  const setFormValues = article => {
    setValue('title', article.title || '')
    setValue('about', article.body || '')
    setValue('category', article.category || 'Latest')
    setPreview(article.image_url || null)
    setImageUploaded(Boolean(article.image_url))
  }

  useEffect(() => {
    fetchArticle()
  }, [fetchArticle])

  const handleImageChange = e => {
    const file = e.target.files?.[0]
    if (file) {
      const url = URL.createObjectURL(file)
      setPreview(url)
      setValue('newsimage', url)
      setImageUploaded(true)
    }
  }

  const handlePencilClick = () => {
    imageInput.current?.click()
  }

  const onSubmit = data => {
    const formData = new FormData()
    formData.append('title', data.title)
    formData.append('body', data.about)
    formData.append('category', data.category)
    formData.append('image', data.newsimage)

    updateArticle(formData)
  }

  const handleStatusChange = status => {
    const formData = new FormData()
    formData.append('status', status)

    updateArticle(formData)
  }

  const textToShow =
    user.role === 'approver' ? 'Cover Image' : 'Upload cover image'

  return (
    <div className='w-full flex flex-col lg:flex-row'>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='w-full lg:w-[70%] h-full p-[2%] bg-white space-y-3'
      >
        <BackButton />
        <h1 className='font-semibold w-full'>News</h1>
        <h1 className='w-full flex justify-start items-center gap-2 ts'>
          <Clock size={15} /> Status:
          <span className=' text-[#F29339] '>{data.status}</span>
        </h1>
        <div className='flex'>
          <div className='w-full lg:w-[50%] m-2'>
            <TextInput
              name='title'
              register={register}
              errors={errors}
              text='Title'
              value={data.title}
              disabled={user.role === 'approver'}
            />
          </div>
          <div className='w-full lg:w-[50%] m-2'>
            <label className='block mb-2 text-sm font-medium text-gray-700'>
              Category
            </label>
            <select
              id='category'
              name='category'
              {...register('category', { required: 'Category is required' })}
              className='w-full p-2 border border-gray-300 rounded-md'
              disabled={user.role === 'approver'}
            >
              <option value='trending'>Trending</option>
              <option value='latest'>Latest</option>
              <option value='livestock'>Livestock</option>
              <option value='crops'>Crops</option>
              <option value='investment'>Investment</option>
              <option value='popular'>Popular</option>
              <option value='products'>Products</option>
            </select>
            {errors.category && (
              <p className='text-red-500'>{errors.category.message}</p>
            )}
          </div>
        </div>

        <div className='grid w-full gap-1.5 ts'>
          <h1>{textToShow}</h1>
          {user.role !== 'approver' && (
            <ImageInput
              preview={preview}
              imageInput={imageInput}
              handleImageChange={handleImageChange}
              handlePencilClick={handlePencilClick}
            />
          )}
          {user.role === 'approver' && (
            <div className=' w-full h-[360px] bg-[#888] rounded-lg overflow-hidden'>
              <img
                src={data.image_url}
                alt='Cover'
                className=' w-full h-full object-cover '
              />
            </div>
          )}
          {errors.newsimage && (
            <p className='text-red-500'>{errors.newsimage.message}</p>
          )}
        </div>
        <div className='grid w-full gap-1.5 ts'>
          <h1>News body</h1>
          <TextArea
            name='about'
            register={register}
            errors={errors}
            text=''
            value={data.body}
            disabled={user.role === 'approver'}
          />
        </div>
        {data.status !== 'approved' &&
          (user.role === 'approver'
            ? (
              <div className='flex gap-5'>
                <button
                  type='button'
                  className='w-full lg:w-[200px] h-[50px] rounded-[8px] bg-green-500 text-white'
                  onClick={() => handleStatusChange('approved')}
                >
                  Approve
                </button>
                <button
                  type='button'
                  className='w-full lg:w-[200px] h-[50px] rounded-[8px] bg-red-500 text-white'
                  onClick={() => handleStatusChange('rejected')}
                >
                  Reject
                </button>
              </div>
              )
            : (
              <button
                type='submit'
                className={`w-full lg:w-[408px] h-[50px] ${
                isValid && isDirty && imageUploaded
                  ? 'proceed-btn bg-darkGreen'
                  : 'rounded-[8px] bg-darkash text-black'
              }`}
                disabled={!isValid}
              >
                Submit
              </button>
              ))}
      </form>
      {(user.role === 'approver' || user.id === data.created_by) && (
        <div>
          <NoteComponent data={data} table='article' />
        </div>
      )}
      <Modal
        type={modalData.type}
        title={modalData.title}
        desc={modalData.desc}
        link={modalData.link}
      />
    </div>
  )
}

export default NewsArticleDetails
