import React from 'react'

export const columns = [
  {
    accessorKey: 'title',
    header: 'Title',
    cell: context => <span className='ts'>{context.getValue()}</span>
  },
  {
    accessorKey: 'created_by_name',
    header: 'Created by',
    cell: context => <span className='ts'>{context.getValue()}</span>
  },
  {
    accessorKey: 'created_at',
    header: 'Created at',
    cell: context => {
      const date = new Date(context.getValue())
      return <span className='ts'>{date.toLocaleDateString()}</span>
    }
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: context => {
      const value = context.getValue()
      return (
        <span
          style={{
            color:
              value === 'rejected'
                ? 'red'
                : value === 'approved'
                  ? 'green'
                  : '#F29339'
          }}
        >
          {value}
        </span>
      )
    }
  },
  {
    accessorKey: 'action',
    header: 'Action',
    enableHiding: false,
    cell: () => <p className='font-[500] ts'>View</p>
  }
]
