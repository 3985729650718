export const MobileColumns = [
  {
    accessorKey: 'farm',
    header: 'Farm',
    cell: context => {
      const farmName = context.getValue()
      const words = farmName.split(' ')

      // Check if the farm name exceeds a certain number of words, e.g., 3
      const formattedName = words.length > 2
        ? `${words.slice(0, 2).join(' ')}...`
        : farmName

      return (
        <span>{formattedName}</span>
      )
    }
  },
  {
    accessorKey: 'product',
    header: 'Product'
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: context => {
      const value = context.getValue()
      return (
        <span
          style={{
            color:
              value === 'Completed'
                ? 'green'
                : value === 'Pending'
                  ? 'orange'
                  : 'black'
          }}
        >
          {value}
        </span>
      )
    }
  }
]
