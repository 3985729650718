import React, { useState } from 'react'
import { businessData } from '../../../data/static'

const EnterpriseBusinessTypeSelector = ({ setSelectedValue, save }) => {
  const [selectedBusinessType, setSelectedBusinessType] = useState(null)
  const [selectedSubtypes, setSelectedSubtypes] = useState({})

  const handleBusinessTypeClick = (businessType) => {
    setSelectedBusinessType(businessType)
    setSelectedSubtypes({})
  }

  const handleSubtypeClick = (subtype) => {
    setSelectedSubtypes((prevSubtypes) => {
      const newSubtypes = { ...prevSubtypes }
      if (newSubtypes[subtype]) {
        delete newSubtypes[subtype]
      } else {
        newSubtypes[subtype] = true
      }
      return newSubtypes
    })
  }

  const isSelected = (subtype) => selectedSubtypes[subtype]

  return (
    <dialog id='business' className='modal modal-middle m-auto'>
      <form method='dialog' className='w-[90%] lg:w-[40%] h-[80vh] bg-white p-6 rounded-lg'>
        <h1 className='font-semibold text-sm lg:text-lg'>Type of Business</h1>
        <div className='w-full flex justify-center items-center border border-gray-200 ts rounded-md p-3 h-[62vh] mt-2'>
          {/* Left Scrollable Section */}
          <div className='w-1/2 h-full overflow-y-scroll pr-2 border-r border-gray-200'>
            {Object.keys(businessData).map((businessType, index) => (
              <div
                key={index}
                className={`p-2 cursor-pointer ${selectedBusinessType === businessType ? 'font-bold' : 'hover:font-bold'}`}
                onClick={() => handleBusinessTypeClick(businessType)}
              >
                {businessType}
              </div>
            ))}
          </div>
          {/* Right Scrollable Section */}
          <div className='w-1/2 h-full overflow-y-scroll pl-2'>
            {businessData[selectedBusinessType]?.map((subtype, index) => (
              <div
                key={index}
                className={`p-2 cursor-pointer ${isSelected(subtype) ? 'font-bold' : 'hover:font-bold'}`}
                onClick={() => handleSubtypeClick(subtype)}
              >
                {subtype}
              </div>
            ))}
          </div>
        </div>
        <div className='w-full flex justify-start items-center gap-2 mt-3'>
          <button
            type='button'
            className='w-[30%] h-[50px] bg-darkGreen rounded-md text-white ts'
            onClick={() => {
              setSelectedValue(selectedSubtypes)
              save()
              document.getElementById('business').close()
            }}
          >
            Continue
          </button>
          <button
            type='button'
            className='w-[30%] h-[50px] bg-[#DDDDDD] rounded-md text-black ts'
            onClick={() => {
              setSelectedValue({})
              document.getElementById('business').close()
            }}
          >
            Cancel
          </button>
        </div>
      </form>
    </dialog>
  )
}

export default EnterpriseBusinessTypeSelector
