import { ChevronLeft, ChevronRight } from 'lucide-react'
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  isEqual,
  isSameMonth,
  isToday,
  parse
} from 'date-fns'
import { useState } from 'react'

const colStartClasses = [
  '', // Sunday
  'col-start-2', // Monday
  'col-start-3', // Tuesday
  'col-start-4', // Wednesday
  'col-start-5', // Thursday
  'col-start-6', // Friday
  'col-start-7' // Saturday
]

function classNames (...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function OperationalCalendarSelector ({ selectedDay, setSelectedDay, today, onSave }) {
  const [currentMonth, setCurrentMonth] = useState(format(today, 'MMM-yyyy'))
  const firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date())

  const days = eachDayOfInterval({
    start: firstDayCurrentMonth,
    end: endOfMonth(firstDayCurrentMonth)
  })

  function previousMonth () {
    const firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 })
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'))
  }

  function nextMonth () {
    const firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 })
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'))
  }

  return (
    <dialog id='calendar' className='modal modal-middle m-auto'>
      <form method='dialog' className='w-[90%] lg:w-[40%] h-[65%] lg:h-[55vh] bg-white p-6 rounded-lg'>
        <div className='w-full gap-6 flex justify-around items-center'>
          <button
            type='button'
            onClick={previousMonth}
            className='flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500'
          >
            <ChevronLeft className='w-5 h-5' aria-hidden='true' />
          </button>
          <h2 className='font-semibold text-gray-900'>
            {format(firstDayCurrentMonth, 'MMMM yyyy')}
          </h2>
          <button
            onClick={nextMonth}
            type='button'
            className='flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500'
          >
            <ChevronRight className='w-5 h-5' aria-hidden='true' />
          </button>
        </div>
        <div className='grid grid-cols-7 mt-6 text-xs leading-6 text-center text-black font-semibold'>
          <div>SUN</div>
          <div>MON</div>
          <div>TUE</div>
          <div>WED</div>
          <div>THU</div>
          <div>FRI</div>
          <div>SAT</div>
        </div>
        <div className='grid grid-cols-7 mt-2 text-sm'>
          {days.map((day, dayIdx) => (
            <div
              key={day.toString()}
              className={classNames(
                dayIdx === 0 && colStartClasses[getDay(day)],
                'py-1.5'
              )}
            >
              <button
                type='button'
                onClick={() => setSelectedDay(day)}
                className={classNames(
                  isEqual(day, selectedDay) && 'text-white',
                  !isEqual(day, selectedDay) &&
                        isToday(day) &&
                        'text-red-500',
                  !isEqual(day, selectedDay) &&
                        !isToday(day) &&
                        isSameMonth(day, firstDayCurrentMonth) &&
                        'text-gray-900',
                  !isEqual(day, selectedDay) &&
                        !isToday(day) &&
                        !isSameMonth(day, firstDayCurrentMonth) &&
                        'text-gray-400',
                  isEqual(day, selectedDay) && isToday(day) && 'bg-red-500',
                  isEqual(day, selectedDay) &&
                        !isToday(day) &&
                        'bg-darkGreen',
                  !isEqual(day, selectedDay) && 'hover:bg-gray-200',
                  (isEqual(day, selectedDay) || isToday(day)) &&
                        'font-semibold',
                  'mx-auto flex h-8 w-8 items-center justify-center rounded-full'
                )}
              >
                <time dateTime={format(day, 'yyyy-MM-dd')}>
                  {format(day, 'd')}
                </time>
              </button>

            </div>
          ))}
        </div>
        <div className='w-full flex gap-2 mt-3'>
          <button
            type='button'
            className='w-[50%] h-[50px] bg-[#DDDDDD] rounded-md text-black ts'
            onClick={() => {
              setSelectedDay('') // Reset selected day
              document.getElementById('calendar').close() // Close the modal
            }}
          >
            Cancel
          </button>

          <button
            type='button'
            className='w-[50%] h-[50px] bg-darkGreen rounded-md text-white ts'
            onClick={() => {
              onSave()
              document.getElementById('calendar').close()
            }}
          >
            Save
          </button>
        </div>
      </form>
    </dialog>
  )
}
