export const columns = [
  {
    accessorKey: 'farm',
    header: 'Farm'
  },
  {
    accessorKey: 'product',
    header: 'Product'
  },
  {
    accessorKey: 'date',
    header: 'Date'
  },
  {
    accessorKey: 'location',
    header: 'Location'
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: context => {
      const value = context.getValue()
      return (
        <span
          style={{
            color:
                value === 'Completed'
                  ? 'green'
                  : value === 'Pending'
                    ? 'orange'
                    : 'black'
          }}
        >
          {value}
        </span>
      )
    }
  }
]
