import React, { useState, useEffect } from 'react'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import {
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  isEqual,
  parseISO,
  startOfMonth,
  addMonths,
  subMonths
} from 'date-fns'

const colStartClasses = [
  '', // Sunday
  'col-start-2', // Monday
  'col-start-3', // Tuesday
  'col-start-4', // Wednesday
  'col-start-5', // Thursday
  'col-start-6', // Friday
  'col-start-7' // Saturday
]

function classNames (...classes) {
  return classes.filter(Boolean).join(' ')
}

const OperationalDescriptionCalendar = ({ adminCalendarDescription }) => {
  const [currentDate, setCurrentDate] = useState(new Date())
  const [events, setEvents] = useState([])

  useEffect(() => {
    // Parse event dates and store them
    const parsedEvents = adminCalendarDescription.Event.map(event => ({
      date: parseISO(event.date),
      description: event.eventdescription
    }))
    setEvents(parsedEvents)
  }, [adminCalendarDescription.Event])

  // Determine the first and last day of the month for the current date
  const firstDayOfMonth = startOfMonth(currentDate)
  const lastDayOfMonth = endOfMonth(currentDate)

  const days = eachDayOfInterval({
    start: firstDayOfMonth,
    end: lastDayOfMonth
  })

  // Navigation functions
  const goToPreviousMonth = () => setCurrentDate(subMonths(currentDate, 1))
  const goToNextMonth = () => setCurrentDate(addMonths(currentDate, 1))

  return (
    <div className='w-[100%] h-[38vh] bg-white'>
      <div className='w-full gap-6 flex justify-around items-center'>
        <button
          type='button'
          className='flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500'
          onClick={goToPreviousMonth}
        >
          <ChevronLeft className='w-5 h-5' aria-hidden='true' />
        </button>
        <h2 className='font-semibold text-gray-900'>
          {format(currentDate, 'MMMM yyyy')}
        </h2>
        <button
          type='button'
          className='flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500'
          onClick={goToNextMonth}
        >
          <ChevronRight className='w-5 h-5' aria-hidden='true' />
        </button>
      </div>

      <div className='grid grid-cols-7 mt-6 text-xs leading-6 text-center text-black font-semibold'>
        <div>SUN</div>
        <div>MON</div>
        <div>TUE</div>
        <div>WED</div>
        <div>THU</div>
        <div>FRI</div>
        <div>SAT</div>
      </div>

      <div className='grid grid-cols-7 mt-2 text-sm'>
        {days.map((day, dayIdx) => (
          <div
            key={day.toString()}
            className={classNames(
              dayIdx === 0 && colStartClasses[getDay(day)],
              'py-1.5'
            )}
          >
            <button
              className={classNames(
                events.some(event => isEqual(day, event.date)) && 'bg-darkGreen text-white',
                'mx-auto flex h-8 w-8 items-center justify-center rounded-full hover:bg-gray-200 ts'
              )}
            >
              <time dateTime={format(day, 'yyyy-MM-dd')}>
                {format(day, 'd')}
              </time>
            </button>
          </div>
        ))}
      </div>

    </div>
  )
}

export default OperationalDescriptionCalendar
