import React from 'react'

import { useForm } from 'react-hook-form'
import useBackendService from '../../../../config/services/backend-service'
import { useAuth } from '../../../../config/contexts/authProvider'

const countryOptions = [{ label: 'Nigeria', value: 'NG' }]
const ProPlanAppPurposeRegistration = ({ next }) => {
  const { signupState } = useAuth()

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid }
  } = useForm()
  const { mutate } = useBackendService('/auth/sign_up', 'post', {
    onSuccess: data => {
      next()
    },
    onError: error => {
      console.error('failed:', error)
    }
  })

  const onSubmit = async data => {
    const payload = {
      ...signupState,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.number,
      location: data.location,
      role: 'administrator',
      plan: signupState.plan || 'Pro'
    }
    mutate(payload)
  }

  // const handleCountryChange = event => {
  //   const selectedOption = event.target.value
  //   setSignupField('location', selectedOption)
  // }
  return (
    <div className='content-cover'>
      <div className='w-full  space-y-1'>
        <h1 className='header-text'>Pro Account</h1>
        <p className='text-black '>
          Kindly fill in your details to setup your account
        </p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className='form-wrapper'>
        <div className='grid w-full  items-center gap-1.5'>
          <label htmlFor='firstName'>First Name</label>
          <input
            type='text'
            name='firstName'
            {...register('firstName', { required: 'First name is required' })}
            placeholder='John'
            className='email-input input-style'
          />
          {errors.firstName && (
            <p className='error-text'>{errors.firstName.message}</p>
          )}
        </div>
        <div className='grid w-full items-center gap-1.5'>
          <label htmlFor='lastName'>Last Name</label>
          <input
            type='text'
            name='lastName'
            {...register('lastName', { required: 'Last name is required' })}
            placeholder='Doe'
            className='email-input input-style'
          />
          {errors.lastName && (
            <p className='error-text'>{errors.lastName.message}</p>
          )}
        </div>
        <div className='grid w-full  items-center gap-1.5'>
          <label htmlFor='text'>Phone number</label>
          <input
            type='number'
            name='number'
            {...register('number', { required: 'Phone number is required' })}
            placeholder='+234'
            className='input-style email-input'
          />
          {errors.number && (
            <p className='error-text'>{errors.number.message}</p>
          )}
        </div>

        <div className='grid w-full items-center gap-1.5'>
          <label htmlFor='location'>Location(s)</label>
          <select
            name='location'
            {...register('location', { required: 'Location is required' })}
            className='select select-bordered w-full border-none bg-lightash'
            // onChange={handleCountryChange}
            defaultValue='NG'
          >
            <option value='' disabled selected>
              Select your country
            </option>
            {countryOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div className='grid w-full  items-center gap-1.5'>
          <label htmlFor='text'>Purpose for using app</label>
          <select
            name='Select a purpose'
            className=' select select-bordered w-full border-none bg-lightash '
            onChange={e => console.log(e.target.value)}
            {...register('purpose', { required: 'Purpose is required' })}
          >
            <option disabled selected>
              Select a purpose
            </option>
            <option>
              I am new to farming and would like to start a small farm
            </option>
            <option>I would like to receive relevant tips on farming</option>
            <option>I would like to receive meteorological data</option>
            <option>Others</option>
          </select>
        </div>
        <button
          type='submit'
          className={`${isDirty && isValid ? 'activebutton' : 'notactive'}`}
          disabled={!(isDirty && isValid)}
        >
          Proceed
        </button>
      </form>

      <p className='login-text'>
        Have an account?{' '}
        <a href='/login' className='login-link'>
          Log in
        </a>
      </p>
    </div>
  )
}

export default ProPlanAppPurposeRegistration
