import React from 'react'
import { NavLink } from 'react-router-dom'

function Footer () {
  return (
    <div className='lg:px-[150px] lg:pt-[100px] px-[5%] py-3'>
      <div className='flex flex-col lg:flex-row py-10 gap-5  lg:justify-between'>
        <div className='lg:hidden'>
          <img
            src={require('../../assets/logo.png')}
            alt=''
            className=' h-[15px]'
          />
        </div>
        <div className=' flex-col hidden lg:flex   lg:items-start lg:justify-start items-center justify-center gap-2 lg:gap-5'>
          <img
            src={require('../../assets/logo.png')}
            alt=''
            className=' h-[15px] lg:h-[30px]'
          />
          <div className='flex flex-row gap-1 lg:gap-5  lg:mr-0 '>
            <img
              src={require('../../assets/twitter.png')}
              alt='socials'
              className='lg:w-[26px] lg:h-[26px] w-[14px] h-[14px] object-fill cursor-pointer'
            />
            <img
              src={require('../../assets/insta.png')}
              alt='socials'
              className='lg:w-[26px] lg:h-[26px] w-[14px] h-[14px] object-fill cursor-pointer'
            />
            <img
              src={require('../../assets/facebook.png')}
              alt='socials'
              className='lg:w-[26px] lg:h-[26px] w-[14px] h-[14px] object-fill cursor-pointer'
            />
          </div>
        </div>
        <div className=' flex flex-row  gap-10 lg:gap-50'>
          <div className='flex flex-col gap-5'>
            <p className='font-[500] text-[12px] lg:text-[18px] text-[#263238] cursor-pointer'>
              Pages
            </p>
            <div className='flex flex-col gap-2'>
              <NavLink
                to='/'
                className={({ isActive }) =>
                  isActive
                    ? 'text-primary font-[900] text-[11px] lg:text-[16px] cursor-pointer'
                    : ' font-[500] text-[11px] lg:text-[16px] text-[#263238] cursor-pointer'}
              >
                <p>Home</p>
              </NavLink>
              <NavLink
                to='/signup'
                className={({ isActive }) =>
                  isActive
                    ? 'text-primary font-[900] text-[11px] lg:text-[16px] cursor-pointer'
                    : 'font-[500] text-[11px] lg:text-[16px] text-[#263238] cursor-pointer'}
              >
                <p>Enterprise</p>
              </NavLink>
              <NavLink
                to='/signup'
                className={({ isActive }) =>
                  isActive
                    ? 'text-primary font-[900] text-[11px] lg:text-[16px] cursor-pointer'
                    : 'font-[500] text-[11px] lg:text-[16px] text-[#263238] cursor-pointer'}
              >
                <p>Basic & Pro</p>
              </NavLink>
            </div>
          </div>
          <div className='flex flex-col gap-5'>
            <p className='font-[500] text-[12px] lg:text-[18px] text-[#263238] cursor-pointer'>
              Contact
            </p>
            <div className='flex flex-col gap-2'>
              <p className='font-[500] text-[11px] lg:text-[16px] text-[#263238] cursor-pointer'>
                support@tgaims.com
              </p>
              <p className='font-[500] text-[11px] lg:text-[16px] text-[#263238] cursor-pointer'>
                +234 801 2345 678
              </p>
              <p className='font-[500] text-[11px] lg:text-[16px] text-[#263238] cursor-pointer'>
                No 15 Yemen road Yemen
              </p>
            </div>
          </div>
          <div className='flex flex-col gap-5'>
            <p className='font-[500] text-[12px] lg:text-[18px] text-[#263238] cursor-pointer'>
              Legal
            </p>
            <div className='flex flex-col gap-2'>
              <p className='font-[500] text-[11px] lg:text-[16px] text-[#263238] cursor-pointer'>
                Privacy Policy
              </p>
              <p className='font-[500] text-[11px] lg:text-[16px] text-[#263238] cursor-pointer'>
                Terms & Conditions
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-row gap-3 lg:gap-5  lg:hidden pb-4 '>
        <img
          src={require('../../assets/twitter.png')}
          alt='socials'
          className='lg:w-[26px] lg:h-[26px] w-[26px] h-[26px] object-fill cursor-pointer'
        />
        <img
          src={require('../../assets/insta.png')}
          alt='socials'
          className='lg:w-[26px] lg:h-[26px] w-[26px] h-[26px] object-fill cursor-pointer'
        />
        <img
          src={require('../../assets/facebook.png')}
          alt='socials'
          className='lg:w-[26px] lg:h-[26px] w-[26px] h-[26px] object-fill cursor-pointer'
        />
      </div>
      <div className='flex flex-row gap-20 border-t-[0.5px] lg:gap-40 items-center justify-center  lg:mt-[101px] mb-[30px] lg:mb-[60px]'>
        <p className='font-[400] text-[11px] uppercase lg:text-[16px] pt-5 text-[#263238]'>
          All rights reserved. Copyrights © 2022 Ltd.
        </p>
      </div>
    </div>
  )
}

export default Footer
