import React, { useEffect, useState } from 'react'
import InvestInformationContainer from '../InvestmentInformationContainer/InvestmentInformationContainer'
import useBackendService from '../../../../config/services/backend-service'

import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function InvestInformation () {
  const { id } = useParams()
  const [data, setData] = useState({})
  const { mutate: fetchInvestment } = useBackendService(
    `/investments/${id}`,
    'get',
    {
      onSuccess: response => {
        setData(response.data)
      },
      onError: error => {
        console.error('Failed to fetch Catalogue data:', error)
        toast.error('Failed to fetch Catalogue data')
      }
    }
  )

  useEffect(() => {
    fetchInvestment()
  }, [fetchInvestment])
  return (
    <main className=' bg-white p-5 '>
      <InvestInformationContainer item={data} />
    </main>
  )
}

export default InvestInformation
