import React from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import './Products.css'
import {
  BasicPlanProductContent,
  EnterpriseProductsContent,
  ProPlanProductContent
} from '../../static'
import { Link } from 'react-router-dom'
import useWindowSize from '../../../farmapp/components/EnterpriseApp/UseWindowSize/UseWindowSize'

function Products () {
  const { width } = useWindowSize()

  return (
    <main className='flex flex-col gap-[20px]'>
      <Header />
      <section className='products_hero'>
        <div className='text-center flex flex-col gap-[10px] p-5 w-full lg:w-[60%] mx-auto'>
          <p className='text-[22px] lg:text-[40px] text-black font-[700] leading-[26px] lg:leading-[60px]'>
            Discover the perfect plan to meet your agribusiness needs
          </p>
          <p className='text-grey font-[500] text-[13px] lg:text-[16px] leading-[19.5px] lg:leading-[24px]'>
            From essential tools for small farm owners to comprehensive solutions for large-scale operations, we offer a range of options designed to help you manage your farm efficiently.
          </p>
        </div>
        <div className='flex justify-center p-0'>
          <img
            src={require('../../assets/productmockup.png')}
            className='w-[90%] mx-auto lg:w-[723px] lg:h-[430px]'
            alt='Product Mockup'
          />
        </div>
      </section>

      <section className='products_pricing'>
        <div className='flex flex-col gap-[14px] text-start lg:p-[80px] p-5 w-full'>
          <p className='text-primary text-[18px] lg:text-[30px] font-[700] w-full lg:w-[50%]'>
            Learn more about the right plan for your interest
          </p>
          <p className='text-grey font-[400] lg:w-[40%] text-[13px] lg:text-[16px]'>
            Whether you're overseeing agriculture investment portfolios, farms (large, medium or small scale enterprise), our features ensure you have everything you need to succeed.
          </p>
        </div>

        {/* Basic Plan Section */}
        <div className='plans'>
          {width > 1024
            ? (
              <div className='pricing_headers'>
                <div className='pricing-header_content'>
                  <p className='font-[400] lg:font-[500] text-[10px] lg:text-[18px] text-white'>
                    Perfect for small farm owners, the Basic Plan offers essential tools for efficient farm management.
                  </p>
                  <Link to='/signup/basicplan'>
                    <div className='pricing-header_button'>
                      <p className='text-primary font-[500] text-[9px] lg:text-[14px]'>Choose plan</p>
                    </div>
                  </Link>
                </div>
                <div className=' pricimg-header_amount '>
                  <p>Basic plan</p>
                  <p className='text-start'>
                    FREE
                  </p>
                </div>
              </div>
              )
            : (
              <div className='px-3 w-full'>
                <div
                  className='w-full text-white h-[280px] md:h-[400px] rounded-lg bg-prodbg bg-no-repeat p-4 flex flex-col justify-start items-center'
                  style={{ backgroundSize: 'cover', backgroundPosition: 'center' }}
                >
                  <div className='w-full flex flex-col justify-center items-center gap-3'>
                    <h1 className='text-darkGreen text-[18px] font-[600]'>Basic Plan</h1>
                    <h1 className='text-darkGreen text-[20px] font-[600]'>FREE</h1>
                  </div>
                  <div className='w-full flex flex-col mt-[10%] justify-center items-center gap-5'>
                    <p className='text-center text-[14px] leading-[21px] text-white font-[400]'>
                      Perfect for small farm owners, the Basic Plan offers essential tools for efficient farm management.
                    </p>
                    <Link to='/signup/basicplan' className='w-full'>
                      <div className='pricing-header_button w-full h-[46px]'>
                        <p className='text-primary font-[500] text-[13px] lg:text-[14px]'>Choose plan</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              )}

          <div className='pricing-content'>
            <div className='grid grid-cols-1 lg:grid-cols-3 gap-3 lg:gap-10'>
              {BasicPlanProductContent.map((item, index) => (
                <div className='flex flex-col gap-1 lg:gap-5' key={index}>
                  <div
                    className={`lg:w-[36px] w-[24px] h-[24px] lg:h-[36px] rounded-full flex justify-center items-center text-[12px] lg:text-[16px] font-[500] 
                    ${item.id % 2 !== 0 ? 'bg-darkGreen text-white' : 'bg-[#ADEE68] text-darkGreen'}`}
                  >
                    {item.id}
                  </div>
                  <p className='text-black text-[14px] lg:text-[16px] font-[600]'>{item.title}</p>
                  <p className='text-grey font-[400] text-[13px] lg:text-[14px]'>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Pro Plan Section */}
        <div className='plans'>
          {width > 1024
            ? (
              <div className='pricing_headers'>
                <div className='pricing-header_content'>
                  <p className='font-[400] lg:font-[500] text-[10px] lg:text-[18px] text-white'>
                    Enjoy enhanced analytics, detailed reporting, and better resource management to boost productivity and streamline operations.
                  </p>
                  <Link to='/signup/proplan'>
                    <div className='pricing-header_button'>
                      <p className='text-primary font-[500] text-[9px] lg:text-[14px]'>Choose plan</p>
                    </div>
                  </Link>
                </div>
                <div className=' pricimg-header_amount '>
                  <p>Pro plan</p>
                  <p className='flex items-center justify-end lg:justify-center'>
                    ₦5,000/{' '}
                    <span>
                      <p className=' text-[8px] lg:text-[20px] '>month</p>
                    </span>{' '}
                  </p>
                </div>
              </div>
              )
            : (
              <div className='px-3 w-full'>
                <div
                  className='w-full text-white h-[280px] md:h-[400px] rounded-lg bg-prodbg bg-no-repeat p-4 flex flex-col justify-start items-center'
                  style={{ backgroundSize: 'cover', backgroundPosition: 'center' }}
                >
                  <div className='w-full flex flex-col justify-center items-center gap-3'>
                    <h1 className='text-darkGreen text-[18px] font-[600]'>Pro Plan</h1>
                    <h1 className='text-darkGreen text-[20px] font-[600]'>
                      <p className='flex items-end justify-center'>
                        ₦5,000/{' '}
                        <span>
                          <p className=' text-[12px] lg:text-[20px] '>month</p>
                        </span>{' '}
                      </p>
                    </h1>
                  </div>
                  <div className='w-full flex flex-col mt-[10%] justify-center items-center gap-5'>
                    <p className='text-center text-[14px] leading-[21px] text-white font-[400]'>
                      Ideal for growing agribusinesses that need advanced farm management tools.
                    </p>
                    <Link to='/signup/proplan' className='w-full'>
                      <div className='pricing-header_button w-full h-[46px]'>
                        <p className='text-primary font-[500] text-[13px] lg:text-[14px]'>Choose plan</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              )}

          <div className='pricing-content'>
            <div className='grid grid-cols-1 lg:grid-cols-3 gap-3 lg:gap-10'>
              {ProPlanProductContent.map((item, index) => (
                <div className='flex flex-col gap-1 lg:gap-5' key={index}>
                  <div
                    className={`lg:w-[36px] w-[24px] h-[24px] lg:h-[36px] rounded-full flex justify-center items-center text-[12px] lg:text-[16px] font-[500] 
                    ${item.id % 2 !== 0 ? 'bg-darkGreen text-white' : 'bg-[#ADEE68] text-darkGreen'}`}
                  >
                    {item.id}
                  </div>
                  <p className='text-black text-[14px] lg:text-[16px] font-[600]'>{item.title}</p>
                  <p className='text-grey font-[400] text-[13px] lg:text-[14px]'>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Enterprise Plan Section */}
        <div className='plans'>
          {width > 1024
            ? (
              <div className='pricing_headers'>
                <div className='pricing-header_content'>
                  <p className='font-[400] lg:font-[500] text-[10px] lg:text-[18px] text-white'>
                    Designed for large-scale farms and livestock operations, the Enterprise Plan provides comprehensive solutions for complex management needs
                  </p>
                  <Link to='/signup/enterpriseplan'>
                    <div className='pricing-header_button'>
                      <p className='text-primary font-[500] text-[9px] lg:text-[14px]'>Choose plan</p>
                    </div>
                  </Link>
                </div>
                <div className=' pricimg-header_amount '>
                  <p>Enterprise plan</p>
                  <p className='flex items-center justify-end lg:justify-center'>
                    ₦100,000/{' '}
                    <span>
                      <p className=' text-[8px] lg:text-[20px] '>month</p>
                    </span>{' '}
                  </p>
                </div>
              </div>
              )
            : (
              <div className='px-3 w-full'>
                <div
                  className='w-full text-white h-[280px] md:h-[400px] rounded-lg bg-prodbg bg-no-repeat p-4 flex flex-col justify-start items-center'
                  style={{ backgroundSize: 'cover', backgroundPosition: 'center' }}
                >
                  <div className='w-full flex flex-col justify-center items-center gap-3'>
                    <h1 className='text-darkGreen text-[18px] font-[600]'>Enterprise Plan</h1>
                    <h1 className='text-darkGreen text-[20px] font-[600]'>
                      <p className='flex items-end justify-center'>
                        ₦100,000/{' '}
                        <span>
                          <p className=' text-[12px] lg:text-[20px] '>month</p>
                        </span>{' '}
                      </p>
                    </h1>
                  </div>
                  <div className='w-full flex flex-col mt-[10%] justify-center items-center gap-5'>
                    <p className='text-center text-[14px] leading-[21px] text-white font-[400]'>
                      A full-scale solution designed for large-scale farming operations and agriculture portfolios.
                    </p>
                    <Link to='/signup/enterpriseplan' className='w-full'>
                      <div className='pricing-header_button w-full h-[46px]'>
                        <p className='text-primary font-[500] text-[13px] lg:text-[14px]'>Choose plan</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              )}

          <div className='pricing-content'>
            <div className='grid grid-cols-1 lg:grid-cols-3 gap-3 lg:gap-10'>
              {EnterpriseProductsContent.map((item, index) => (
                <div className='flex flex-col gap-1 lg:gap-5' key={index}>
                  <div
                    className={`lg:w-[36px] w-[24px] h-[24px] lg:h-[36px] rounded-full flex justify-center items-center text-[12px] lg:text-[16px] font-[500] 
                    ${item.id % 2 !== 0 ? 'bg-darkGreen text-white' : 'bg-[#ADEE68] text-darkGreen'}`}
                  >
                    {item.id}
                  </div>
                  <p className='text-black text-[14px] lg:text-[16px] font-[600]'>{item.title}</p>
                  <p className='text-grey font-[400] text-[13px] lg:text-[14px]'>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <div className='w-full relative flex items-center justify-center'>
        <h1 className='absolute font-[600] text-base text-[18px] leading-[30px] lg:text-[36px] lg:leading-[63px] text-center px-[5%] text-white'>
          We help you take charge and run your Agricultural processes more efficiently all from one platform
        </h1>
        <img
          src={require('../../assets/plant.png')}
          className='w-full h-[200px] lg:h-[400px] object-cover'
          alt=''
        />
      </div>
      <Footer />
    </main>
  )
}

export default Products
